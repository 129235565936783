<template>
  <div>
    <!-- Multi tag -->
    <div class="flex flex-nowrap flex-initial">
      <!-- Values -->
      <div ref="tags">
        <th-tags
          v-model="form"
          class="flex flex-wrap overflow-hidden h-10"
          :dynamic-size="hasHiddenTags"
          :editable="editable"
        />
      </div>

      <div class="flex flex-nowrap items-start">
        <!-- Hidden tags counter -->
        <th-tag
          v-if="hasHiddenTags"
          class="cursor-pointer h-10 pl-2 pr-3"
          @click="showModal"
          >{{ hiddenTagsCounter }}</th-tag
        >
        <!-- Delete -->
        <el-tooltip v-if="editable" effect="dark" :content="deleteTooltip">
          <el-button
            icon="Delete"
            class="el-button--text-icon"
            @click="$emit('deleteAll')"
          />
        </el-tooltip>
      </div>
    </div>
    <!-- Modal -->
    <modal v-model="isModalOpen" height="auto" scrollable>
      <div class="py-10 px-10">
        <h3 v-if="modalHeader" class="text-center" v-text="modalHeader" />
        <div class="flex flex-wrap">
          <th-tags
            class="flex flex-wrap"
            :model-value="modalForm"
            :editable="editable"
            @update:modelValue="modalForm = $event"
          />
        </div>
        <div class="flex justify-end pt-10">
          <el-button
            @click="closeModal"
            v-text="$t('common.interactions.buttons.cancel')"
          />
          <el-button
            type="primary"
            @click="handleSave"
            v-text="$t('common.interactions.buttons.save')"
          />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import * as uuid from 'uuid'
import ThTags from '@components/tags/tags'
import ThTag from '@components/tags/tag'

export default {
  name: 'ThSingleLineTagsInput',
  components: {
    ThTags,
    ThTag
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    editable: {
      type: Boolean,
      default: false
    },
    deleteTooltip: {
      type: String,
      default: ''
    },
    modalHeader: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isModalOpen: false,
      hiddenTagsCount: 0,
      modalForm: []
    }
  },
  computed: {
    modalName() {
      return `tags-modal-${uuid.v4()}`
    },
    hiddenTagsCounter() {
      return `+${this.hiddenTagsCount}`
    },
    hasHiddenTags() {
      return this.hiddenTagsCount !== 0
    },
    /*
    Reversing the array only for visual purposes.
    Since we want the new pushed item (last in the array) will be presented first in the UI.
    the returned array will be reversed again.
    */
    form: {
      get() {
        return [...this.modelValue].reverse()
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue.reverse())
      }
    }
  },
  mounted() {
    this.calculateTagCounts()
    window.addEventListener('resize', this.calculateTagCounts)
  },
  updated() {
    this.calculateTagCounts()
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.calculateTagCounts)
  },
  methods: {
    showModal() {
      this.modalForm = [...this.form]
      this.isModalOpen = true
    },
    closeModal() {
      this.isModalOpen = false
    },
    handleSave() {
      this.form = this.modalForm
      this.closeModal()
    },
    calculateTagCounts() {
      //if element offset height from parent is bigger than the element height, the element is hidden from user.
      // for example the element is 40px heigh, if the offset is 0px the element is visiable.
      // if the offset is bigger than 40px, the element is in the second row (which is hidden in the UI).
      const tags = [...this.$refs.tags.children[0].children]
      this.hiddenTagsCount = tags.reduce((count, el) => {
        const isElOverflow = el.offsetTop > el.offsetHeight
        return isElOverflow ? count + 1 : count
      }, 0)
    }
  }
}
</script>
