<template>
  <div class="flex">
    <th-tag
      v-for="(val, valIndex) in form"
      :key="valIndex"
      :closable="editable"
      :class="{ 'flex-grow': dynamicSize }"
      class="px-2 py-0 mx-1 mb-2 h-10"
      @close="removeValue(valIndex)"
    >
      {{ val }}
    </th-tag>
  </div>
</template>

<script>
import ThTag from '@components/tags/tag.vue'
export default {
  name: 'ThTags',
  components: {
    ThTag
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    editable: {
      type: Boolean,
      default: false
    },
    dynamicSize: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    }
  },
  methods: {
    removeValue(valueIndex) {
      const temp = this.form
      temp.splice(valueIndex, 1)
      this.form = temp
    }
  }
}
</script>
