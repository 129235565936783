<template>
  <div
    class="tag w-max flex items-center justify-evenly text-xs border border-solid rounded box-border whitespace-nowrap pr-1"
    @click="$emit('click')"
  >
    <slot />
    <button
      v-if="closable"
      class="tag-close flex items-center justify-around rounded-full cursor-pointer w-4 h-4 ml-2 focus:outline-none focus:shadow-outline"
      :aria-label="$t('common.interactions.buttons.close')"
      @click.prevent="$emit('close')"
    >
      <svgicon
        :src="require('@/assets/icons/th-icon-close.svg')"
        :style="{
          height: '10px',
          width: '10px',
          fill: 'var(--primary-color)'
        }"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: 'ThTag',
  props: {
    closable: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.tag {
  background-color: #ecf5ff;
  border-color: #d9ecff;
  color: #409eff;
}
.tag-close:hover {
  --primary-color: white;
  background-color: #409eff;
}
</style>
